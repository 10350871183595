<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="'SALVAMENTOS'"
      :subtitle="'Formulario para crear y editar salvamentos'"
      :hide-buttons="true"
    >
      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información general</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="category" class="w-full select-large">Categoría</label>
          <v-select
            id="category"
            name="category"
            label="name"
            index="id"
            :options="categories"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.category"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("category") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input
            id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("name") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="claim_code" class="w-full select-large">Código de Reclamo</label>
          <vs-input
            id="claim_code" name="claim_code" v-model="entryModel.claim_code" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("claim_code") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de salvamento</vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="price" class="w-full select-large">Precio</label>
          <vs-input
            id="price" name="price" v-model="entryModel.price" v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("price") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="condition" class="w-full select-large">Condición de salvamento</label>
          <vs-input
            id="condition" name="condition" v-model="entryModel.condition" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("condition") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="brand" class="w-full select-large">Marca</label>
          <vs-input
            id="brand" name="brand" v-model="entryModel.features.brand" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("brand") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="model" class="w-full select-large">Modelo</label>
          <vs-input
            id="model" name="model" v-model="entryModel.features.model" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("model") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="year" class="w-full select-large">Año</label>
          <vs-input
            id="year" name="year" v-model="entryModel.features.year" v-validate="'required|digits:4'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("year") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="license_plate" class="w-full select-large">Placa</label>
          <vs-input
            id="license_plate" name="license_plate" v-model="entryModel.features.license_plate" class="w-full"
            v-validate="'required'" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("license_plate") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="chassis" class="w-full select-large">Chasis</label>
          <vs-input
            id="chassis" name="chassis" v-model="entryModel.features.chassis" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("chassis") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="motor" class="w-full select-large">Motor</label>
          <vs-input
            id="motor" name="motor" v-model="entryModel.features.motor" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("motor") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Ubicación</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="state" class="w-full select-large">Departamento</label>
          <v-select
            id="state"
            name="state"
            label="name"
            index="id"
            :options="states"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.state"
            v-validate="'required'"
            @input="getCities(entryModel.state)"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("state") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="city" class="w-full select-large">Ciudad</label>
          <v-select
            id="city"
            name="city"
            label="name"
            index="id"
            :options="cities"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.city"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("city") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="address" class="w-full select-large">Dirección</label>
          <vs-textarea
            id="address" name="address" v-model="entryModel.address" v-validate="'required'" rows="3"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("address") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="featured" class="w-full select-large">Destacado</label>
          <vs-select
            id="featured" name="featured" v-model="entryModel.featured" v-validate="'required'"
            class="w-full select-large"
          >
            <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
            <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
          </vs-select>
          <span class="text-danger">{{ errors.first("featured") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="featured" class="w-full select-large">Aprobado</label>
          <vs-select
            id="approved" name="approved" v-model="entryModel.approved" v-validate="'required'"
            class="w-full select-large"
          >
            <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
            <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
          </vs-select>
          <span class="text-danger">{{ errors.first("approved") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="priority" class="w-full select-large">Prioridad</label>
          <vs-input
            type="number"
            id="priority"
            name="priority"
            min="1"
            max="10"
            v-model="entryModel.priority"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("priority") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de contacto</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="contact_info_name" class="w-full select-large">Nombre</label>
          <vs-input
            id="contact_info_name" name="contact_info_name" v-model="entryModel.contact_info.name"
            v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("contact_info_name") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="contact_info_phone_number" class="w-full select-large">Teléfono</label>
          <vs-input
            id="contact_info_phone_number" name="contact_info_phone_number"
            v-model="entryModel.contact_info.phone_number" v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("contact_info_phone_number") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="contact_info_email" class="w-full select-large">Correo</label>
          <vs-input
            id="contact_info_email" name="contact_info_email" v-model="entryModel.contact_info.email"
            v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("contact_info_email") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Publicación</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="publish_at" class="w-full select-large">Fecha y hora de publicación</label>
          <flat-pickr
            :config="configdateTimePicker"
            id="publish_at"
            name="publish_at"
            v-model="entryModel.publish_at"
            class="w-full flatpickr flatpickr-input"
          />
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="expire_at" class="w-full select-large">Fecha y hora de expiración</label>
          <flat-pickr
            :config="configdateTimePicker"
            id="expire_at"
            v-model="entryModel.expire_at"
            class="w-full flatpickr flatpickr-input"
          />
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToEntriesList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry" :disabled="!validateForm">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from 'vee-validate';

  let notifier = new Notifier();
  let configurations = new Configurations();
  const ENTRY_CLIENT = new EntriesClient("salvages");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("salvages");
  const STATES_CLIENT = new CoreClient("states");
  const CITIES_CLIENT = new EntriesClient("cities");

  const requiredFields = [
    "name", "claim_code", "category", "brand", "model", "year", "license_plate", "chassis", "motor", "price", "condition",
    "state", "city", "address", "featured", "approved", "priority", "contact_info_name", "contact_info_phone_number",
    "contact_info_email"
  ];
  let dict = requiredFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  dict["year"].digits = "* Ingrese un valor válido";
  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        configdateTimePicker: configurations.configDatePicker(),
        resource: "salvages",
        categories: [],
        states: [],
        cities: [],
        entryModel: {
          state: null,
          city: null,
          price: null,
          address: null,
          name: null,
          claim_code: null,
          priority: 10,
          approved: true,
          featured: false,
          features: {},
          contact_info: {
            name: null,
            phone_number: null,
            email: null
          },
          condition: null,
          category: null,
          publish_at: null,
          expire_at: null
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
        });
      } else {
        this.$vs.loading();
        this.getCategories(false).then(() => {
          this.getStates().then(() => {
            this.$vs.loading.close();
          });
        });
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
          && requiredFields.reduce((accumulator, currentValue) => {
            return accumulator && this.entryModel[currentValue] !== null;
          });
      }
    },

    methods: {
      async getStates(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await STATES_CLIENT.all()
          .then(response => {
            this.states = response.data;
          }).catch(error => {
            notifier.notification("error");
          });
        this.$vs.loading.close();
      },

      async getCities(state, showLoading = true) {
        if (state !== null && state !== undefined && state !== "") {
          if (showLoading) this.$vs.loading();

          await CITIES_CLIENT.all({queryStrings: {state_id: state.id}})
            .then(response => {
              this.cities = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
        } else {
          this.cities = [];
          this.entryModel.city = null;
        }
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_CLIENT.categories({
          queryStrings: {representation_type: "simple"}
        })
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.categories = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getStates(false)
              .then(() => {
                if (response.data.city !== undefined && response.data.city.id !== null) {
                  this.getCities(response.data.state, false);
                }
              })
              .then(() => {
                this.getCategories(false);
              })
              .then(() => {
                Object.assign(this.entryModel, response.data);
              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          this.$vs.loading();
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });

        } else {
          this.$vs.loading();
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      goToEntriesList() {
        this.$router.push({name: "salvages"});
      }
    },
    components: {
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    },
  };
</script>